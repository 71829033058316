import { useFormik } from "formik"
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import { PfButton } from "@profabric/react-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSync } from "@fortawesome/free-solid-svg-icons";
import { getApiKeyContents, submitApiKey } from "../../services/apikey";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import "../../App.css";

const ApiKeyForm = ({showForm, contents, data, closeModalHandler}) => {
  const [isEdit, setEdit] = useState(!data)
  const [isLoading, setLoading] = useState(false)
  const [expDate, setExpDate] = useState(new Date())
  const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: '',
      api_key: '',
      app_id: '',
      client_type: '',
      origins: '',
      expired_date: '',
      token: '',
      passkey: '',
      contents: []
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(6, 'Minimal 6 karakter')
        .max(128, 'Maksimal 128 karakter')
        .required('Harus diisi'),
      api_key: Yup.string()
        .min(24, 'Minimal 24 karakter')
        .max(48, 'Maksimal 48 karakter')
        .required('Harus diisi'),
      app_id: Yup.string()
        .min(6, 'Minimal 6 karakter')
        .max(64, 'Maksimal 64 karakter')
        .required('Harus diisi'),
      client_type: Yup.string()
        .required('Pilih salah satu')
        .oneOf(['mobile', 'web']),
      origins: Yup.string()
        .when('client_type', {
          is: 'web',
          then: (schema) => schema.required('Harus diisi'),
          otherwise: (schema) => schema.optional()
        }),
      expired_date: Yup.string()
        .required('Harus diisi'),
      token: Yup.string()
        .max(64, 'Maksimal 64 karakter'),
      passkey: Yup.string()
        .max(64, 'Maksimal 64 karakter'),
    }),
    onSubmit: async (values) => {
      // console.log(values)
      const id = data ? data.api_key : ""

      if (values.origins === '') {
        delete values.origins
      }

      const body = JSON.stringify(values)

      try {
        setLoading(true)
        const resp = await submitApiKey(id, body)
        setLoading(false)

        if (resp.hasOwnProperty('code') && resp.code > 204) {
          toast.warn(resp.message)
        } else {
          toast.success('Data berhasil disimpan')
          closeModal(true)
        }
      } catch (err) {
        console.error(err)
        toast.error(err.message)
        setLoading(false)
      }
    }
  })

  const closeModal = (needRefresh) => {
    resetForm()
    closeModalHandler(needRefresh)
  }

  const handleOnShow = async () => {
    if (data) {
      setEdit(false)
      setFieldValue('api_key', data.api_key)
      setFieldValue('name', data.name)
      setFieldValue('app_id', data.app_id)
      setFieldValue('client_type', data.client_type)
      setFieldValue('origins', data.origins)
      setFieldValue('expired_date', data.expired_date)
      setFieldValue('token', data.token)
      setFieldValue('passkey', data.passkey)

      const d = new Date(data.expired_date)
      handleExpDateChange(d)

      try {
        const resp = await getApiKeyContents(data.api_key)
        if (resp.status === 'error') {
          if (resp.code !== 404) {
            toast.warn(resp.message)
          }
        } else {
          let contents = []
          resp.data.map((v) => (
            contents.push(v.id)
          ))

          setFieldValue('contents', contents)
        }
      } catch (err) {
        console.error(err)
        toast.error(err.message)
      }
    } else {
      handleExpDateChange(new Date())
      setEdit(true)
    }
  }

  const handleEditClick = () => {
    setEdit(true)
  }

  const handleExpDateChange = date => {
    date.setHours(23, 59, 59, 999)
    setExpDate(date)
    setFieldValue('expired_date', date.toISOString())
  }

  const generateKey = keyLen => {
    let result     = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let charactersLength = characters.length
    for ( let i = 0; i < keyLen; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  const generateApiKey = async () => {
    const key = generateKey(24)

    await setFieldValue('api_key', key, true)
  }

  const generateToken = () => {
    const key = generateKey(32)
    const now = (new Date()).getTime() + ''

    setFieldValue('token', key + now, true)
  }

  const generatePasskey = () => {
    const key = generateKey(32)
    const now = (new Date()).getTime() + ''

    setFieldValue('passkey', key + now, true)
  }

  const compileCheckboxLabel = (content) => {
    let typeLabel = ""
    if (content.type === "murotal") {
      typeLabel = "Murotal "
    }

    return typeLabel + content.name
  }

  const cancel = () => {
    closeModal(false)
  }

  return (
    <Modal 
      id="modal-apikey-form" 
      show={showForm} 
      size="lg" 
      onShow={handleOnShow}
    >
      <Modal.Header>
        <h4 className="modal-title">{data ? 'Detail API Key' : 'Tambah API Key'}</h4>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancel}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <Row className="mb-3 justify-content-md-between">
              <Form.Group as={Col} md="8">
                <Form.Label htmlFor="api_key">API Key</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="form-apikey"
                    id="api_key"
                    name="api_key"
                    placeholder="Masukkan API key"
                    value={values.api_key}
                    onChange={handleChange}
                    isValid={touched.api_key && !errors.api_key}
                    isInvalid={touched.api_key && errors.api_key}
                    readOnly
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text' style={{padding: 0}}>
                      <Button 
                        variant="link" 
                        style={{borderWidth: '0px'}} 
                        title="Generate"
                        onClick={generateApiKey}
                        disabled={data || !isEdit}
                      >
                        <FontAwesomeIcon icon={faSync} />
                      </Button>
                    </div>
                  </div>
                </InputGroup>
                {touched.api_key && errors.api_key ? (
                  <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {errors.api_key}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Col md="auto">
                {data && !isEdit ? (
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleEditClick}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    &nbsp;
                    Ubah data
                  </Button>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="name">Nama Klien</Form.Label>
                <Form.Control
                  className="form-apikey"
                  id="name"
                  name="name"
                  placeholder="Masukkan nama klien"
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                  disabled={!isEdit}
                  autoComplete="off"
                />
                {touched.name && errors.name ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="app_id">ID Aplikasi</Form.Label>
                <Form.Control
                  className="form-apikey"
                  id="app_id"
                  name="app_id"
                  placeholder="Masukkan application ID atau nama domain"
                  value={values.app_id}
                  onChange={handleChange}
                  isValid={touched.app_id && !errors.app_id}
                  isInvalid={touched.app_id && errors.app_id}
                  disabled={!isEdit}
                />
                {touched.app_id && errors.app_id ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.app_id}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="expired_date">Berlaku sampai</Form.Label>
                <DatePicker 
                  id="expired_date"
                  name="expired_date" 
                  className="form-control" 
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  placeholderText="Berlaku sampai"
                  selected={expDate}
                  onChange={(date) => handleExpDateChange(date)}
                  disabled={!isEdit}
                  customInput={(
                    <Form.Control
                      id="expired_date"
                      name="expired_date"
                      className="datetimepicker-input form-apikey"
                      placeholder="Berlaku sampai"
                      value={values.expired_date}
                      isValid={touched.expired_date && !errors.expired_date}
                      isInvalid={touched.expired_date && errors.expired_date}
                    />
                  )}
                />
                {touched.expired_date && errors.expired_date ? (
                  <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {errors.expired_date}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>Jenis Klien</Form.Label>
                <div>
                  <Form.Check 
                    inline 
                    id="client_type-mobile"
                    name="client_type" 
                    type="radio" 
                    label="Mobile" 
                    value="mobile" 
                    onChange={handleChange} 
                    disabled={!isEdit}
                    checked={values.client_type === 'mobile'} 
                    isInvalid={touched.client_type && errors.client_type}
                  />
                  <Form.Check 
                    inline 
                    id="client_type-web"
                    name="client_type" 
                    type="radio" 
                    label="Web" 
                    value="web" 
                    onChange={handleChange} 
                    disabled={!isEdit}
                    checked={values.client_type === 'web'} 
                    isInvalid={touched.client_type && errors.client_type}
                  />
                </div>
                {touched.client_type && errors.client_type ? (
                  <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {errors.client_type}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="origins">Domain yang Diizinkan (pisahkan dengan koma)</Form.Label>
                <Form.Control
                  className="form-apikey"
                  id="origins"
                  name="origins"
                  placeholder="Masukkan application ID atau nama domain"
                  value={values.origins}
                  onChange={handleChange}
                  isValid={touched.origins && !errors.origins}
                  isInvalid={touched.origins && errors.origins}
                  disabled={!isEdit}
                />
                {touched.origins && errors.origins ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.origins}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="token">Token API</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="form-apikey"
                    id="token"
                    name="token"
                    placeholder="Masukkan token API"
                    value={values.token}
                    onChange={handleChange}
                    isValid={touched.token && !errors.token}
                    isInvalid={touched.token && errors.token}
                    readOnly
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text' style={{padding: 0}}>
                      <Button 
                        variant="link" 
                        style={{borderWidth: '0px'}} 
                        onClick={generateToken}
                        disabled={!isEdit}
                      >
                        <FontAwesomeIcon icon={faSync} />
                      </Button>
                    </div>
                  </div>
                </InputGroup>
                {touched.token && errors.token ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.token}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="passkey">Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="form-apikey"
                    id="passkey"
                    name="passkey"
                    placeholder="Masukkan password"
                    value={values.passkey}
                    onChange={handleChange}
                    isValid={touched.passkey && !errors.passkey}
                    isInvalid={touched.passkey && errors.passkey}
                    readOnly
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text' style={{padding: 0}}>
                      <Button 
                        variant="link" 
                        style={{borderWidth: '0px'}} 
                        onClick={generatePasskey}
                        disabled={!isEdit}
                      >
                        <FontAwesomeIcon icon={faSync} />
                      </Button>
                    </div>
                  </div>
                </InputGroup>
                {touched.passkey && errors.passkey ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.passkey}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label>Konten</Form.Label>
                {contents.map((content) => (
                  <Form.Check 
                    key={content.id}
                    id={content.id}
                    name="contents"
                    type="checkbox"
                    label={compileCheckboxLabel(content)}
                    value={content.id}
                    onChange={handleChange}
                    className="mb-2"
                    checked={values.contents.includes(content.id)}
                    disabled={!isEdit}
                  />
                ))}
              </Form.Group>
            </Row>
          </div>
          <div className="modal-footer justify-content-between">
            <button 
              type="button" 
              className="btn btn-default" 
              data-dismiss="modal" 
              onClick={cancel}
            >
              Tutup
            </button>
            <PfButton type="submit" loading={isLoading} disabled={!isEdit}>Simpan</PfButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ApiKeyForm