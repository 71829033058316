export const authLogin = (username, password) => {
    return new Promise(async (res, rej) => {
        let profile = {}
        await fetch(process.env.REACT_APP_API_URL + "/users/signin", {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(async (response) => {
            if (response.status === 200) {
                return response.json()
            }

            const txt = await response.text()
            throw new Error(txt)
        })
        .then((resp) => {
            profile = resp.data
            localStorage.setItem('authentication', JSON.stringify(profile))
        })
        .catch((err) => {
            console.error(err)
            try {
                profile = JSON.parse(err.message)
            } catch (e) {
                console.error(e)
                profile = {
                    status: 'error',
                    message: "Couldn't connect to server"
                }
            }
        })

        return res(profile)
    })
}

export const authLogout = () => {
    return new Promise(async (res, rej) => {
        let authentication = localStorage.getItem('authentication')
        if (authentication) {
            authentication = JSON.parse(authentication)
        } else {
            return res({
                status: 'error',
                message: 'token tidak ditemukan'
            })
        }

        let r = {}
        await fetch(process.env.REACT_APP_API_URL + "/users/signout", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authentication.token
            },
        })
        .then(async (response) => {
            if (response.status >= 200 && response.status <= 204) {
                return true
            }

            const txt = await response.text()
            throw new Error(txt)
        })
        .then((resp) => {
            r = true
            localStorage.setItem('authentication', undefined)
        })
        .catch((err) => {
            console.error(err)
            r = true
            localStorage.setItem('authentication', undefined)
        })
        
        return res(r)
    })
}

export const getAuthStatus = () => {
    return new Promise(async (res, rej) => {
        try {
            let authentication = localStorage.getItem('authentication')
            if (authentication) {
                authentication = JSON.parse(authentication)
                return res(authentication)
            }

            return res(undefined)
        } catch (error) {
            return res(undefined)
        }
    })
}