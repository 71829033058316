import React, { useEffect, useState } from "react"
import PageWrapper from "../../components/Content/PageWrapper"
import PageContent from "../../components/Content/PageContent"
import PageHeader from "../../components/Content/PageHeader"
import Card from "../../components/Card/Card"
import DataTable from "react-data-table-component"
import { getApiKeys } from "../../services/apikey"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setAuthentication } from "../../store/reducers/auth"
import ApiKeyForm from "./ApiKeyForm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons"
import { getPublishedContents } from "../../services/content"
import { Button, Col, Row } from "react-bootstrap"
import ApiKeyDeleteConfirm from "./ApiKeyDeleteConfirm"
import { paginationOptions, tableStyle } from "../../utils/helpers"

const ApiKeyPage = () => {
  const [contents, setContents] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [sortCol, setSortCol] = useState('')
  const [sortDir, setSortDir] = useState('asc')
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [selectedDatum, setSelectedDatum] = useState(null)
  const [filterStr, setFilterStr] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const columns = [
    {
      id: 'client_name',
      name: "Nama Klien",
      selector: row => row.name,
      sortable: true
    },
    {
      id: 'api_key',
      name: "API Key",
      selector: row => row.api_key,
    },
    {
      id: 'client_type',
      name: "Jenis Klien",
      width: '100px',
      selector: row => row.client_type.charAt(0).toUpperCase() + row.client_type.substring(1),
    },
    {
      id: 'expired_date',
      name: "Berlaku Sampai",
      width: '150px',
      right: true,
      selector: row => {
        const d = new Date(row.expired_date)
        return d.toLocaleDateString('in-ID')
      },
      sortable: true
    },
    {
      id: 'actions',
      name: 'Aksi',
      width: '120px',
      cell: (row, index, column, id) => {
        return <Button variant="secondary" onClick={handleDelete} id={row.api_key} value={JSON.stringify(row)}>Hapus</Button>
      }
    }
  ]
  
  const handleDelete = (state) => {
    const obj = JSON.parse(state.target.value)
    setSelectedDatum(obj)
    setShowDeleteConfirm(true)
  }
  
  const handleAddApiKey = () => {
    if (contents.length < 1) {
      toast.error('Tidak ada data konten')
    }
    setShowForm(true)
  }

  const closeModal = (needRefresh) => {
    setSelectedDatum(null)
    setShowForm(false)
    setShowDeleteConfirm(false)

    if (needRefresh) {
      fetchData(page, perPage, sortCol, sortDir)
    }
  }

  const handlePerRowsChange = async (perPage, page) => {
    setPerPage(perPage)
    setPage(page)
    fetchData(page, perPage, sortCol, sortDir)
  }

  const handlePageChange = page => {
    setPage(page)
    fetchData(page, perPage, sortCol, sortDir)
  }

  const handleSortChange = (col, dir) => {
    setSortCol(col.id)
    setSortDir(dir)

    fetchData(page, perPage, col.id, dir)
  }

  const handleRowClick = (row) => {
    if (contents.length < 1) {
      toast.error('Tidak ada data konten')
    }
    
    setSelectedDatum(row)
    setShowForm(true)
  }

  const handleRefreshClick = () => {
    setPage(1)
    fetchData(1, perPage, sortCol, sortDir)
  }

  const handleFilterKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleRefreshClick()
    }
  }

  const fetchData = async (page, perPage, sortCol, sortDir) => {
    const offset = (page - 1) * perPage
    
    setLoading(true)
    const resp = await getApiKeys(filterStr, perPage, offset, sortCol, sortDir)

    if (resp.hasOwnProperty('status')) {
      if (resp.status === 'error') {
        if (resp.code === 401) {
          dispatch(setAuthentication(null))
          navigate('/login')
          return
        }

        toast.warn(resp.message)
      }
    } else {
      setTotalRows(resp.recordsFiltered)
      setData(resp.data)
    }

    setLoading(false)
  }

  const fetchContents = async () => {
    const resp = await getPublishedContents()

    if (resp.hasOwnProperty('status')) {
      if (resp.status === "OK") {
        setContents(resp.data)
      }
    }
  }

  useEffect(() => {
    fetchData(page, perPage, sortCol, sortDir)
    fetchContents()
  }, [])

  return (
    <PageWrapper>
      <PageHeader title="API Keys" />
      <PageContent>
        <div className="row">
          <div className="col-12">
            <Card 
              title="Daftar API Key Klien"
              customCardToolActions={
                <span>
                  <button
                    type="button"
                    className="btn btn-tool"
                    title="Muat Ulang"
                    onClick={handleRefreshClick}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </button>
                  <button
                    type='button'
                    className='btn btn-tool'
                    title="Tambah API Key"
                    onClick={handleAddApiKey}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </span>
              }
              footer={
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddApiKey}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;
                  Tambah API Key
                </button>
              }
            >
              <Row>
                <Col sm="12" md="6"></Col>
                <Col sm="12" md="2"></Col>
                <Col sm="12" md="4">
                  <div className="dataTables_filter">
                    <input 
                      id="apikey_filter" 
                      type="search" 
                      className="form-control form-control-sm" 
                      placeholder="Masukkan nama klien untuk mencari" 
                      value={filterStr}
                      onChange={e => setFilterStr(e.target.value)} 
                      onKeyDown={handleFilterKeyDown} />
                  </div>
                </Col>
              </Row>
              <DataTable 
                columns={columns}
                data={data}
                progressPending={loading}
                defaultSortFieldId={'client_name'}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationComponentOptions={paginationOptions}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer
                onSort={handleSortChange}
                onRowClicked={handleRowClick}
                highlightOnHover
                customStyles={tableStyle}
              />
            </Card>
          </div>
        </div>
      </PageContent>
      <ApiKeyForm showForm={showForm} contents={contents} data={selectedDatum} closeModalHandler={closeModal} />
      <ApiKeyDeleteConfirm showModal={showDeleteConfirm} closeModalHandler={closeModal} data={selectedDatum} />
    </PageWrapper>
  )
}

export default ApiKeyPage