import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class PageContent extends Component {
  render() {
    const { children } = this.props

    return (
      <section className='content'>
        <div className='content-fluid'>
          {children}
        </div>
      </section>
    )
  }
}

PageContent.propTypes = {
  children: PropTypes.node
}
PageContent.defaultProps = {
  children: null
}

export default PageContent