import React from "react";
import PageHeader from "../components/Content/PageHeader";
import PageContent from "../components/Content/PageContent";
import PageWrapper from "../components/Content/PageWrapper";

const BlankPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="QuranBest Content Management" />
      <PageContent>
        <div></div>
      </PageContent>
    </PageWrapper>
  )
}

export default BlankPage