import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import { useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import * as Yup from 'yup'
import { submitContent } from "../../services/content"
import { toast } from "react-toastify"
import { PfButton } from "@profabric/react-components"

const ContentForm = ({showForm, data, closeModalHandler}) => {
  const [isEdit, setEdit] = useState(!data)
  const [isLoading, setLoading] = useState(false)
  const { handleChange, handleSubmit, resetForm, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      id: 0,
      code: '',
      name: '',
      description: '',
      format: '',
      subformat: '',
      language: '',
      source: '',
      type: '',
      direction: '',
      filesize: 0,
      status: '',
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .min(5, 'Minimal 5 karakter')
        .max(25, 'Maksimal 25 karakter')
        .required('Harus diisi'),
      name: Yup.string()
        .min(6, 'Minimal 6 karakter')
        .max(50, 'Maksimal 50 karakter')
        .required('Harus diisi'),
      description: Yup.string()
        .max(1000, 'Maksimal 1000 karakter'),
      format: Yup.string()
        .required('Pilih salah satu')
        .oneOf(['audio', 'image', 'text']),
      subformat: Yup.string()
        .required('Pilih salah satu')
        .oneOf(['perayah', 'persurah', 'perpage']),
      language: Yup.string()
        .required('Pilih salah satu')
        .oneOf(['ar', 'en', 'id']),
      type: Yup.string()
        .required('Pilih salah satu')
        .oneOf(['quran', 'murotal', 'translate', 'transliterate']),
      status: Yup.string()
        .required('Pilih salah satu')
        .oneOf(['draft', 'publish', 'unpublish']),
      filesize: Yup.number()
        .min(1, 'Minimal 1 KB')
        .max(200000, 'Maksimal 200 MB')
    }),
    onSubmit: async (values) => {
      console.log(values)
      const body = JSON.stringify(values)

      try {
        setLoading(true)
        const resp = await submitContent(values.id, body)
        setLoading(false)

        if (resp.hasOwnProperty('code') && resp.code > 204) {
          const messages = [<p key="err">{resp.message}</p>]
          if (resp.errors) {
            const items = []
            resp.errors.forEach((el, idx) => {
              items.push(<li key={idx}>{el.message}</li>)
            });
            messages.push(<ul key="items">{items}</ul>)
          }
          toast.warn(<div>{messages}</div>)
        } else {
          toast.success('Data berhasil disimpan')
          closeModal(true)
        }
      } catch (err) {
        console.error(err)
        toast.error(err.message)
        setLoading(false)
      }
    }
  })

  const closeModal = (needRefresh) => {
    resetForm()
    closeModalHandler(needRefresh)
  }

  const cancel = () => {
    closeModal(false)
  }

  const handleShow = () => {
    // console.log(data)
    if (data) {
      setEdit(false)
      setFieldValue('id', data.id)
      setFieldValue('code', data.code)
      setFieldValue('name', data.name)
      setFieldValue('description', data.description === null ? '' : data.description)
      setFieldValue('format', data.format)
      setFieldValue('subformat', data.subformat)
      setFieldValue('language', data.language)
      setFieldValue('source', data.source)
      setFieldValue('type', data.type)
      setFieldValue('direction', data.direction)
      setFieldValue('filesize', data.filesize)
      setFieldValue('status', data.status)
    } else {
      setEdit(true)
    }
  }

  const handleEditClick = () => {
    setEdit(true)
  }

  return (
    <Modal
      id="modal-content-form"
      show={showForm}
      size="lg"
      onShow={handleShow}
    >
      <Modal.Header>
        <h4 className="modal-title">{data ? 'Detail Konten' : 'Tambah Konten'}</h4>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancel}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <Row className="mb-3 justify-content-md-between">
              <Form.Group as={Col} md="6">
                <Form.Label>Kode</Form.Label>
                <Form.Control
                  className="form-apikey"
                  id="code"
                  name="code"
                  placeholder="Masukkan kode konten"
                  value={values.code}
                  onChange={handleChange}
                  disabled={!isEdit}
                  isValid={touched.code && !errors.code}
                  isInvalid={touched.code && errors.code}
                  maxLength={25}
                />
                {touched.code && errors.code ? (
                  <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {errors.code}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Col md="auto">
              {data && !isEdit ? (
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleEditClick}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    &nbsp;
                    Ubah data
                  </Button>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label>Nama</Form.Label>
                <Form.Control
                  className="form-apikey"
                  id="name"
                  name="name"
                  placeholder="Masukkan nama konten"
                  value={values.name}
                  onChange={handleChange}
                  disabled={!isEdit}
                  autoComplete="off"
                  isValid={touched.name && !errors.name}
                  isInvalid={touched.name && errors.name}
                  maxLength={50}
                />
              </Form.Group>
              {touched.name && errors.name ? (
                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                  {errors.name}
                </Form.Control.Feedback>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label>Deskripsi</Form.Label>
                <Form.Control
                  className="form-apikey"
                  id="description"
                  name="description"
                  placeholder="Masukkan deskripsi konten"
                  value={values.description}
                  onChange={handleChange}
                  disabled={!isEdit}
                  as="textarea"
                  rows={3}
                  isValid={touched.description && !errors.description}
                  isInvalid={touched.description && errors.description}
                  maxLength={1000}
                />
              </Form.Group>
              {touched.description && errors.description ? (
                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                  {errors.description}
                </Form.Control.Feedback>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label>Jenis</Form.Label>
                <div>
                  <Form.Check 
                    inline 
                    id="type-quran"
                    name="type" 
                    type="radio" 
                    label="Quran" 
                    value="quran" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.type === 'quran'} 
                    isInvalid={touched.type && errors.type}
                  />
                  <Form.Check 
                    inline 
                    id="type-murotal"
                    name="type" 
                    type="radio" 
                    label="Murotal" 
                    value="murotal" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.type === 'murotal'} 
                    isInvalid={touched.type && errors.type}
                  />
                  <Form.Check 
                    inline 
                    id="type-translate"
                    name="type" 
                    type="radio" 
                    label="Translate/terjemah" 
                    value="translate" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.type === 'translate'} 
                    isInvalid={touched.type && errors.type}
                  />
                  <Form.Check 
                    inline 
                    id="type-transliterate"
                    name="type" 
                    type="radio" 
                    label="Transliterasi" 
                    value="transliterate" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.type === 'transliterate'} 
                    isInvalid={touched.type && errors.type}
                  />
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>Format</Form.Label>
                <div>
                  <Form.Check 
                    inline 
                    id="format-audio"
                    name="format" 
                    type="radio" 
                    label="Audio" 
                    value="audio" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.format === 'audio'} 
                    isInvalid={touched.format && errors.format}
                  />
                  <Form.Check 
                    inline 
                    id="format-image"
                    name="format" 
                    type="radio" 
                    label="Gambar" 
                    value="image" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.format === 'image'} 
                    isInvalid={touched.format && errors.format}
                  />
                  <Form.Check 
                    inline 
                    id="format-text"
                    name="format" 
                    type="radio" 
                    label="Teks" 
                    value="text" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.format === 'text'} 
                    isInvalid={touched.format && errors.format}
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Sub Format</Form.Label>
                <div>
                  <Form.Check 
                    inline 
                    id="subformat-ayah"
                    name="subformat" 
                    type="radio" 
                    label="Per Ayah" 
                    value="perayah" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.subformat === 'perayah'} 
                    isInvalid={touched.subformat && errors.subformat}
                  />
                  <Form.Check 
                    inline 
                    id="subformat-surah"
                    name="subformat" 
                    type="radio" 
                    label="Per Surah" 
                    value="persurah" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.subformat === 'persurah'} 
                    isInvalid={touched.subformat && errors.subformat}
                  />
                  <Form.Check 
                    inline 
                    id="subformat-page"
                    name="subformat" 
                    type="radio" 
                    label="Per Halaman" 
                    value="perpage" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.subformat === 'perpage'} 
                    isInvalid={touched.subformat && errors.subformat}
                  />
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label>Source</Form.Label>
                <Form.Control
                  className="form-apikey"
                  name="source"
                  id="source"
                  placeholder="Masukkan URL konten"
                  value={values.source}
                  onChange={handleChange}
                  disabled={!isEdit}
                  autoComplete="off"
                  isValid={touched.source && !errors.source}
                  isInvalid={touched.source && errors.source}
                  maxLength={1000}
                />
              </Form.Group>
              {touched.source && errors.source ? (
                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                  {errors.source}
                </Form.Control.Feedback>
              ) : null}
            </Row>
            <Row className="mb-3">
              <Col md="6">
                <Form.Group>
                  <Form.Label>Ukuran File (dalam KB)</Form.Label>
                  <Form.Control
                    className="form-apikey"
                    name="filesize"
                    id="filesize"
                    placeholder="Masukkan total ukuran file"
                    value={values.filesize}
                    onChange={handleChange}
                    disabled={!isEdit}
                    autoComplete="off"
                    isValid={touched.filesize && !errors.filesize}
                    isInvalid={touched.filesize && errors.filesize}
                    type="number"
                    maxLength={6}
                    max={200000}
                  />
                </Form.Group>
                {touched.filesize && errors.filesize ? (
                  <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {errors.filesize}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
              <Col md="6">
                <Form.Group>
                  <Form.Label>Bahasa</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      id="language-ar"
                      name="language"
                      type="radio"
                      label="Arab"
                      value="ar"
                      onChange={handleChange}
                      disabled={!isEdit}
                      checked={values.language === 'ar'}
                      isInvalid={touched.language && errors.language}
                      />
                    <Form.Check
                      inline
                      id="language-id"
                      name="language"
                      type="radio"
                      label="Indonesia"
                      value="id"
                      onChange={handleChange}
                      disabled={!isEdit}
                      checked={values.language === 'id'}
                      isInvalid={touched.language && errors.language}
                      />
                    <Form.Check
                      inline
                      id="language-en"
                      name="language"
                      type="radio"
                      label="Inggris"
                      value="en"
                      onChange={handleChange}
                      disabled={!isEdit}
                      checked={values.language === 'en'}
                      isInvalid={touched.language && errors.language}
                      />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} md="6">
                <Form.Label>Arah Konten</Form.Label>
                <div>
                  <Form.Check 
                    inline 
                    id="direction-rtl"
                    name="direction" 
                    type="radio" 
                    label="Kanan ke kiri (RTL)" 
                    value="rtl" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.direction === 'rtl'} 
                    isInvalid={touched.direction && errors.direction}
                  />
                  <Form.Check 
                    inline 
                    id="direction-ltr"
                    name="direction" 
                    type="radio" 
                    label="Kiri ke kanan (LTR)" 
                    value="ltr" 
                    onChange={handleChange}
                    disabled={!isEdit}
                    checked={values.direction === 'ltr'} 
                    isInvalid={touched.direction && errors.direction}
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} md="6">
                  <Form.Label>Status</Form.Label>
                  <div>
                    <Form.Check 
                      inline 
                      id="status-draft"
                      name="status" 
                      type="radio" 
                      label="Draft" 
                      value="draft" 
                      onChange={handleChange}
                      disabled={!isEdit}
                      checked={values.status === 'draft'} 
                      isInvalid={touched.status && errors.status}
                    />
                    <Form.Check 
                      inline 
                      id="status-publish"
                      name="status" 
                      type="radio" 
                      label="Publish" 
                      value="publish" 
                      onChange={handleChange}
                      disabled={!isEdit}
                      checked={values.status === 'publish'} 
                      isInvalid={touched.status && errors.status}
                    />
                    <Form.Check 
                      inline 
                      id="status-unpublish"
                      name="status" 
                      type="radio" 
                      label="Unpublished" 
                      value="unpublish" 
                      onChange={handleChange}
                      disabled={!isEdit}
                      checked={values.status === 'unpublish'} 
                      isInvalid={touched.status && errors.status}
                    />
                  </div>
                </Form.Group>
            </Row>
          </div>
          <div className="modal-footer justify-content-between">
            <button 
              type="button" 
              className="btn btn-default" 
              data-dismiss="modal" 
              onClick={cancel}
            >
              Tutup
            </button>
            <PfButton type="submit" loading={isLoading} disabled={!isEdit}>Simpan</PfButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ContentForm