import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class PageWrapper extends Component {
  render() {
    const { children } = this.props
    return (
      <div className='content-wrapper'>{children}</div>
    )
  }
}

PageWrapper.propTypes = {
  children: PropTypes.node
}
PageWrapper.defaultProps = {
  children: null
}

export default PageWrapper