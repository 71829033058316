const BASE_PATH_CONTENT = 'contents'

export const getContents = (query, limit, offset, sortCol, sortDir) => {
    return new Promise(async (res, rej) => {
        let authentication = localStorage.getItem('authentication')
        if (authentication) {
            authentication = JSON.parse(authentication)
        } else {
            return res({
                status: 'error',
                message: 'token tidak ditemukan'
            })
        }

        let url = `${process.env.REACT_APP_API_URL}/${BASE_PATH_CONTENT}/datatable?`

        if (query) {
            url = `${url}query=${query}&`
        }
        if (limit) {
            url = `${url}limit=${limit}&`
        }
        if (offset) {
            url = `${url}offset=${offset}&`
        }
        if (sortCol) {
            url = `${url}sortCol=${sortCol}&`
        }
        if (sortDir) {
            url = `${url}sortDir=${sortDir}`
        }

        let resp = {}
        await fetch(url, {
            headers: {
                'Authorization': `Bearer ${authentication.token}`
            }
        })
        .then(async (response) => {
            if (response.status < 200 || response.status - 200 > 4) {
                const txt = await response.text()
                let err = JSON.parse(txt)
                err.code = response.status
                throw new Error(JSON.stringify(err))
            }

            return response.json()
        })
        .then(r => {
            resp = r
        })
        .catch(err => {
            console.error(err)
            try {
                resp = JSON.parse(err.message)
            } catch (e) {
                console.error(e)
                resp = {
                    status: 'error',
                    message: "Couldn't connect to server"
                }
            }
        })
        
        return res(resp)
    })
}

export const getPublishedContents = () => {
    return new Promise(async (res, rej) => {
        let authentication = localStorage.getItem('authentication')
        if (authentication) {
            authentication = JSON.parse(authentication)
        } else {
            return res({
                status: 'error',
                message: 'token tidak ditemukan'
            })
        }

        let resp = {}
        await fetch(`${process.env.REACT_APP_API_URL}/${BASE_PATH_CONTENT}/status/publish`, {
            headers: {
                'Authorization': `Bearer ${authentication.token}`
            }
        })
        .then(async (response) => {
            if (response.status < 200 || response.status - 200 > 4) {
                const txt = await response.text()
                let err = JSON.parse(txt)
                err.code = response.status
                throw new Error(JSON.stringify(err))
            }

            return response.json()
        })
        .then(r => {
            resp = r
        })
        .catch(err => {
            try {
                resp = JSON.parse(err.message)
            } catch (e) {
                console.error(e)
                resp = {
                    status: 'error',
                    message: "Couldn't connect to server"
                }
            }
        })

        return res(resp)
    })
}

export const submitContent = (contentId, data) => {
    return new Promise(async (res, rej) => {
        let authentication = localStorage.getItem('authentication')
        if (authentication) {
            authentication = JSON.parse(authentication)
        } else {
            return res({
                status: 'error',
                message: 'token tidak ditemukan'
            })
        }
        
        let url = `${process.env.REACT_APP_API_URL}/${BASE_PATH_CONTENT}`
        if (contentId && contentId > 0) {
            url += `/${contentId}`
        }

        let resp = {}

        await fetch(url, {
            method: contentId && contentId > 0 ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authentication.token
            },
            body: data
        })
        .then(async (response) => {
            if (response.status < 200 || response.status - 200 > 4) {
                const txt = await response.text()
                let err = JSON.parse(txt)
                err.code = response.status
                throw new Error(JSON.stringify(err))
            }

            return response.json()
        })
        .then(r => {
            resp = r
        })
        .catch(err => {
            console.error(err)
            try {
                resp = JSON.parse(err.message)
            } catch (e) {
                console.error(e)
                resp = {
                    status: 'error',
                    message: "Couldn't connect to server"
                }
            }
        })
        
        return res(resp)
    })
}