import React, { useEffect, useState } from "react"
import PageWrapper from "../../components/Content/PageWrapper"
import PageContent from "../../components/Content/PageContent"
import PageHeader from "../../components/Content/PageHeader"
import Card from "../../components/Card/Card"
import { Col, Row } from "react-bootstrap"
import { paginationOptions, tableStyle } from "../../utils/helpers"
import DataTable from "react-data-table-component"
import { getContents } from "../../services/content"
import { toast } from "react-toastify"
import { setAuthentication } from "../../store/reducers/auth"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons"
import ContentForm from "./ContentForm"

const Contents = () => {
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [sortCol, setSortCol] = useState('')
  const [sortDir, setSortDir] = useState('asc')
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [selectedDatum, setSelectedDatum] = useState(null)
  const [filterStr, setFilterStr] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const columns = [
    {
      id: 'name',
      name: "Nama Konten",
      selector: row => row.name,
      sortable: true
    },
    {
      id: 'code',
      name: "Kode Konten",
      selector: row => row.code,
    },
    {
      id: 'type',
      name: "Jenis Konten",
      selector: row => row.type.charAt(0).toUpperCase() + row.type.substring(1),
      sortable: true
    },
    {
      id: 'status',
      name: "Status",
      width: '150px',
      cell: (row) => {
        const badge = row.status === 'publish' ? 'badge badge-success' : row.status === 'draft' ? 'badge badge-warning' : 'badge badge-danger'
        return <span className={badge}>{row.status}</span>
      },
      sortable: true
    }
  ]

  const closeModal = (needRefresh) => {
    setSelectedDatum(null)
    setShowForm(false)

    if (needRefresh) {
      fetchData(page, perPage, sortCol, sortDir)
    }
  }

  const handlePerRowsChange = async (perPage, page) => {
    setPerPage(perPage)
    setPage(page)
    fetchData(page, perPage, sortCol, sortDir)
  }

  const handlePageChange = page => {
    setPage(page)
    fetchData(page, perPage, sortCol, sortDir)
  }

  const handleSortChange = (col, dir) => {
    setSortCol(col.id)
    setSortDir(dir)

    fetchData(page, perPage, col.id, dir)
  }

  const handleRowClick = (row) => {
    setSelectedDatum(row)
    setShowForm(true)
  }

  const handleRefreshClick = () => {
    setPage(1)
    fetchData(1, perPage, sortCol, sortDir)
  }

  const handleFilterKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleRefreshClick()
    }
  }

  const handleAddContent = () => {
    setShowForm(true)
  }

  const fetchData = async (page, perPage, sortCol, sortDir) => {
    const offset = (page - 1) * perPage
    
    setLoading(true)
    const resp = await getContents(filterStr, perPage, offset, sortCol, sortDir)

    if (resp.hasOwnProperty('status')) {
      if (resp.status === 'error') {
        if (resp.code === 401) {
          dispatch(setAuthentication(null))
          navigate('/login')
          return
        }

        toast.warn(resp.message)
      }
    } else {
      setTotalRows(resp.recordsFiltered)
      setData(resp.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchData(page, perPage, sortCol, sortDir)
  }, [])
  
  return (
    <PageWrapper>
      <PageHeader title="Contents" />
      <PageContent>
        <Row>
          <Col className="col-12">
            <Card
              title="Daftar Konten"
              customCardToolActions={
                <span>
                  <button
                    type="button"
                    className="btn btn-tool"
                    title="Muat Ulang"
                    onClick={handleRefreshClick}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </button>
                  <button
                    type='button'
                    className='btn btn-tool'
                    title="Tambah API Key"
                    onClick={handleAddContent}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </span>
              }
              footer={
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddContent}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;
                  Tambah Konten
                </button>
              }
            >
              <Row>
                <Col sm="12" md="6"></Col>
                <Col sm="12" md="2"></Col>
                <Col sm="12" md="4">
                  <div className="dataTables_filter">
                    <input 
                      id="content_filter" 
                      type="search" 
                      className="form-control form-control-sm" 
                      placeholder="Masukkan nama konten untuk mencari" 
                      value={filterStr}
                      onChange={e => setFilterStr(e.target.value)} 
                      onKeyDown={handleFilterKeyDown} />
                  </div>
                </Col>
              </Row>
              <DataTable 
                columns={columns}
                data={data}
                progressPending={loading}
                defaultSortFieldId={'client_name'}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationComponentOptions={paginationOptions}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer
                onSort={handleSortChange}
                onRowClicked={handleRowClick}
                highlightOnHover
                customStyles={tableStyle}
              />
            </Card>
          </Col>
        </Row>
      </PageContent>
      <ContentForm showForm={showForm} data={selectedDatum} closeModalHandler={closeModal} />
    </PageWrapper>
  )
}

export default Contents