import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
    const isLoggedIn = useSelector((state) => state.auth.authentication);
    return isLoggedIn ? <Navigate to='/' /> : <Outlet />;
}

export default PublicRoute;
