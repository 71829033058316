export const sleep = ms => new Promise(r => setTimeout(r, ms))

export const paginationOptions = { 
  rowsPerPageText: 'Rows per page:', 
  rangeSeparatorText: 'dari', 
  noRowsPerPage: true, 
  selectAllRowsItem: false, 
  selectAllRowsItemText: 'All' 
}
  
export const tableStyle = {
  rows: {
    style: {
      fontSize: '13px',
      fontWeight: 400,
      color: '#fff',
      backgroundColor: '#343a40',
      minHeight: '48px',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#00000044'
      }
    },
    highlightOnHoverStyle: {
      color: '#ffffffaa',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      backgroundColor: '#000000aa',
      borderBottomColor: '#00000066',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: '#00000066'
    }
  },
  cells: {
    style: {
      backgroundColor: 'transparent'
    }
  },
  pagination: {
    style: {
      color: '#fff',
      backgroundColor: '#343a40'
    },
    pageButtonsStyle: {
      fill: '#ffffff99',
      '&:disabled': {
        fill: '#ffffff33'
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#000000dd'
      }
    }
  },
  noData: {
    style: {
      color: '#fff',
      backgroundColor: '#343a40'
    }
  },
  progress: {
    style: {
      color: '#fff',
      backgroundColor: '#343a40'
    }
  }
}
  