import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setAuthentication } from "../../store/reducers/auth";
import Header from "../../components/Main/Header";
import Sidebar from "../../components/Main/Sidebar";
import Footer from "../../components/Main/Footer";
import { authLogout } from "../../services/auth";
import { toast } from "react-toastify";

const MainPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = async () => {
    const resp = await authLogout()
    if (resp === true) {
      dispatch(setAuthentication(undefined))
      navigate('/login')
    } else {
      toast.warn(resp.message)
    }
  }

  return (
    <div className="wrapper">
      <Header onSignOutClicked={logout} />
      <Sidebar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default MainPage