import React, { Component } from 'react'

export class PageHeader extends Component {
  render() {
    const { title } = this.props
    return (
      <section className='content-header'>
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PageHeader