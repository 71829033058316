import { PfButton } from "@profabric/react-components"
import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import { deleteApiKey } from "../../services/apikey"

const ApiKeyDeleteConfirm = ({data, showModal, closeModalHandler}) => {
  const [loading, setLoading] = useState(false)

  const closeModal = (needRefresh) => {
    closeModalHandler(needRefresh)
  }

  const cancel = () => {
    closeModal(false)
  }

  const handleConfirmation = async () => {
    // console.log(data)
    try {
      setLoading(true)
      const resp = await deleteApiKey(data.api_key)
      setLoading(false)

      if (resp.hasOwnProperty('code') && resp.code > 204) {
        toast.warn(resp.message)
      } else {
        toast.success('Data berhasil disimpan')
        closeModal(true)
      }
    } catch (err) {
      console.error(err)
      toast.error(err.message)
      setLoading(false)
    }
  }

  return (
    <Modal
      id="modal-apikey-delete-confirm"
      show={showModal}
      size="md"
    >
      <Modal.Header>
        <h4 className="modal-title">Yakin Akan Menghapus Data?</h4>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancel}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        Apakah Anda yakin akan menghapus API key untuk klien berikut?<br /><br /><center><strong>{data ? data.name : ''}</strong></center><br />
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          variant="primary"
          onClick={cancel}
        >Batal</Button>
        <PfButton type="secondary" onClick={handleConfirmation} loading={loading}>Hapus</PfButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ApiKeyDeleteConfirm
