import React, { useState } from 'react'
import { Login } from '../../components/Login/Login'
import { useDispatch } from 'react-redux';
import { setAuthentication } from '../../store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import { PfButton } from '@profabric/react-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup } from 'react-bootstrap';
import { authLogin } from '../../services/auth';
import { toast } from 'react-toastify';

const LoginPage = () => {
  const [isAuthLoading, setAuthLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const login = async (username, password) => {
    try {
      setAuthLoading(true)
      const resp = await authLogin(username, password)
      setAuthLoading(false)

      if (resp.hasOwnProperty('status') && resp.status === 'error') {
        toast.warn(resp.message)
      } else {
        dispatch(setAuthentication(resp))
        navigate('/')
      }
    } catch(error) {
      console.error(error)
      toast.error(error.message)
      setAuthLoading(false)
    }
  }

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    validateOnBlur: true,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(5, 'Username minimal 5 karakter')
        .max(15, 'Username maksimal 15 karakter')
        .required('Harus diisi'),
      password: Yup.string()
        .min(5, 'Password minimal 5 karakter')
        .max(15, 'Password maksimal 15 karakter')
        .required('Harus diisi'),
    }),
    onSubmit: (values) => {
      login(values.username, values.password)
    },
  })

  return (
    <div>
      <Login
        header={
          <h2>
            QuranBest Content
          </h2>
        }
        message='Sign in to start your session'
      >
        <form onSubmit={handleSubmit}>
          <InputGroup className='input-group mb-3'>
            <Form.Control 
              id='username'
              name='username'
              type='text' 
              className='form-control' 
              placeholder='Username' 
              onChange={handleChange}
              value={values.username}
              isValid={touched.username && !errors.username}
              isInvalid={touched.username && !!errors.username}
            />
            {touched.username && errors.username ? (
              <Form.Control.Feedback type='invalid'>
                {errors.username}
              </Form.Control.Feedback>
            ) : (
              <div className='input-group-append'>
                <div className='input-group-text'>
                  <span className='fas fa-envelope'></span>
                </div>
              </div>
            )}
          </InputGroup>
          <InputGroup className='input-group mb-3'>
            <Form.Control
              id='password'
              name='password'
              type='password'
              className='form-control'
              placeholder='Password'
              onChange={handleChange}
              value={values.password}
              isValid={touched.password && !errors.password}
              isInvalid={touched.password && !!errors.password}
            />
            {touched.password && errors.password ? (
              <Form.Control.Feedback type='invalid'>
                {errors.password}
              </Form.Control.Feedback>
            ) : (
              <div className='input-group-append'>
                <div className='input-group-text'>
                  <span className='fas fa-lock'></span>
                </div>
              </div>
            )}
          </InputGroup>
          <div className='row'>
            <div className='col-8'>
              <input type='checkbox' id='remember' />
              <label>Remember Me</label>
            </div>
            <div className='col-4'>
              <PfButton
                block
                type='submit'
                loading={isAuthLoading}
              >
                Sign In
              </PfButton>
            </div>
          </div>
        </form>
      </Login>
    </div>
  )
}

export default LoginPage
