import './App.css';
import MainPage from './pages/main/Main';
import LoginPage from './pages/login/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import ApiKeyPage from './pages/apikeys/ApiKey';
import BlankPage from './pages/Blank';
import { useDispatch } from 'react-redux';
import { getAuthStatus } from './services/auth';
import { setAuthentication } from './store/reducers/auth';
import { ToastContainer } from 'react-toastify';
import Contents from './pages/contents/Contents';

const App = () => {
  // const [isAppLoading, setAppLoading] = useState(true);
  const dispatch = useDispatch()

  const checkSession = async () => {
    try {
      let resp = await getAuthStatus()

      if (resp) {
        dispatch(setAuthentication(resp))
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    checkSession()
  }, [])

  // if (isAppLoading) {
  //   return <Loading />
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<PublicRoute />}>
          <Route path='/login' element={<LoginPage />} />
        </Route>
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/' element={<MainPage />}>
            <Route index element={<BlankPage />} />
            <Route path='apikeys' element={<ApiKeyPage />} />
            <Route path='contents' element={<Contents />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
