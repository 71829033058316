import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="main-footer">
          <strong>Copyright © 2018-2023 <a href="https://quranbest.com">QuranBest Indonesia</a>. </strong>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.0
          </div>
        </footer>
      </div>
    )
  }
}
